import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import BG from '../images/awards-bg.jpg'

const NavLink = props => {
	if (!props.test) {
		return <Link to={props.url}>{props.text}</Link>
	} else {
		return <span>{props.text}</span>
	}
}
class Blog extends React.Component {
	render() {
		const { data } = this.props
		const siteTitle = data.site.siteMetadata.title
		const { group, index, first, last } = this.props.pageContext
		const previousUrl = index - 1 === 1 ? '/' : (index - 1).toString()
		const nextUrl = (index + 1).toString()
		console.log(group)
		// console.log(this)
		// console.log(this.props)

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO
					title="All Videos"
					keywords={[`blog`, `gatsby`, `javascript`, `react`]}
				/>
				<div
					id="sliderhead"
					className="blog-header blogheadpost"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
				>
					<div className="container">
						<div className="text-det">
							<h5 className="related-title">Latest Videos</h5>
						</div>
					</div>
				</div>
				<div id="list-allblog" className="main-container">
					<div className="container">
						<div className="col-md-12">
							<div id="list-blog-all">
								{group.map(vid => (
									<div className="news-items" key={vid.node.id}>
										<div className="lead-sec">
											<div className="lead-image">
												<img
													src={
														vid &&
														vid.node &&
														vid.node.mainImage &&
														vid.node.mainImage.asset &&
														vid.node.mainImage.asset.fluid &&
														vid.node.mainImage.asset.fluid.src
													}
													alt="banner"
													className="img-responsive"
												/>
											</div>
										</div>
										<div className="blog-det">
											<h3 className="blog-title">
												<Link to={vid.node.slug.current}>{vid.node.title}</Link>
											</h3>
											<div className="published">
												<p className="info">
													<i className="fa fa-bookmark-o" /> <i>By</i>{' '}
													<b>{vid.node.author.name}</b> |{' '}
													<i className="fa fa-clock-o" /> Created on{' '}
													<b>{vid.node._createdAt}</b>
												</p>
											</div>
											<div className="short-desc">
												<p>{vid.node.excerpt}</p>
												<p className="read-more">
													<Link to={vid.node.slug.current}>
														Read More
														<i
															aria-hidden="true"
															className="fa fa-long-arrow-right"
														/>
													</Link>
												</p>
											</div>
										</div>
									</div>
								))}
								<nav>
									<ul className="pager">
										<li className="previous disabled  d-inline">
											<NavLink
												test={first}
												url={'latest-videos/' + previousUrl}
												text="← Prev"
											/>
										</li>
										<li className="next float-right d-inline">
											<NavLink
												test={last}
												url={'latest-videos/' + nextUrl}
												text="Next →"
											/>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}
export default Blog

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`
